import EmailRegister from "@components/features/RegisterPage/EmailRegister";
import RegisterBackground from "@components/features/RegisterPage/RegisterBackground";

const EmailRegisterPage = () => (
  <RegisterBackground>
    <EmailRegister />
  </RegisterBackground>
);

export default EmailRegisterPage;
