import Card from "@components/common/Card";
import MyInfo from "@components/features/MyPage/Info/MyInfo";

const MyInfoPage = () => (
  <Card title="내 정보 수정" w="100%" h="fit-content" p="10">
    <MyInfo />
  </Card>
);

export default MyInfoPage;
